import React from "react";

//Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import "./css/style.css";
import { builderPartner, instructions } from "./assets";
import loginBg from '../assets/login_background.png';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

//Customizable Area End

export default class ForgotPasswordInstruction extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  //Customizable Area Start
  //Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>        
          <Box className="login-container-box message-box"
          style={{
            backgroundImage: `url(${loginBg})`              
          }}>
            <Box className="login-container">
              <div className="logo-block" style={{textAlign:'center'}}>
                <img src={builderPartner} className="login-partner-logo" />                
              </div>
              <Box className="instructions-window">
                <img src={instructions} className="instruction-logo" />
                <Typography className="success-text">
                  Instructions sent!
                </Typography>
                <Typography className="success-sub-text" style={{width: '490px'}}>
                  If Partnership account exists for the email {this.props.navigation?.getParam("email", '')}, instructions for resetting your password has been sent to it. Check your mailbox.
                </Typography>

                <Box
                  style={{
                    marginTop: '32px'
                  }}
                >
                  <Typography
                    onClick={() => this.backToLogin()}
                    className="btn-instruction-send">
                    Back to sign in
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>        
      </ThemeProvider>
      // Customizable Area End

    );
  }
}

// Customizable Area Start
