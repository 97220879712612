import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  Tabs,
  Tab,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'
import CompanyWeb from "./company.web";
import ContactWeb from "./contact.web";
// Customizable Area End

import Companycontactpage2Controller, {
  Props,
  configJSON,
} from "./Companycontactpage2Controller";
import TableFilterSkeleton from "../../../components/src/TableFilterSkeleton";
import TableSkeleton from "../../../components/src/TableSkeleton";
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import TopNavSkeleton from "../../../components/src/TopNavSkeleton";

export default class Companycontactpage2 extends Companycontactpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getSelectCompanies()
    await this.getIndustry()
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">{
            this.state.isLoading ?
              // <Loader loading={this.state.isLoading} contentLoader={true} />
              <Box className="skeleton-wrapper">
                <TopNavSkeleton />
                <PageHeadingSkeleton />
                <TableFilterSkeleton hasTabbing={true} />
                <TableSkeleton />
              </Box>
              :
              <Grid>
                <TopNavBar currentPage={{ name: "Companies and contacts", path: 'companies' }} />
                <Box style={webStyles.mainContainer}>
                  <Box className="dashboard-nav-container">
                    <Box className="dashboard-heading">
                      <Box>
                        <Typography className="company-heading-main">
                        Companies and contacts
                        </Typography>
                        <Typography className="company-heading-sub">
                          View all referred companies and their contacts.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Grid className="in-table-theme">
                    <Box sx={{ width: '100%' }}>
                      <Box className="custom-tabbing-wrap">
                        <Tabs value={this.state.tabValue}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#6200EA"
                            }
                          }}
                          onChange={(event, newValue) => this.handleTabChange(newValue)} aria-label="basic tabs example" className="custom-tab-list">
                          <Tab disableRipple label="Companies" />
                          <Tab disableRipple label="Contacts" />
                        </Tabs>
                      </Box>
                      <Box className="custom-tab-content-wrap">
                        {
                          this.state.tabValue === 0 ?
                            <CompanyWeb navigation={this.props.navigation} id={""} />
                            :
                            <ContactWeb navigation={this.props.navigation} id={""} />
                        }
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
          }
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
};
// Customizable Area End
