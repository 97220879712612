import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getErrorResponse, getMemberErrorMessages, getStartAndEndDates, getToken, getYearMonthDate, removeAllToken, setToken } from "../../../components/src/commonUsage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: any;
  errorMessage: any;
  comissionData: any;
  isLoading: boolean;
  openCalendar: boolean;
  dateRangeValue: any;
  calendarMenu: any;
  calanderValue: any;
  searchValue: any;
  currentPage: any;
  perPage: any;
  csvData: any;
  payoutsData: any;
  addInvoiceModal: boolean;
  selectedInvoiceData: any;
  viewInvoiceModal: any;
  isUploaded: boolean;
  isDragging: boolean;
  fileSizeError: any;
  fileTypeError: any;
  selectedFile: any;
  fileError: string;
  isTableLoading: boolean;
  dateValue:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Commissionreports2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getComissionDataApiId: string = "";
  downloadCSVComissionId: string = "";
  fileInputRef: any;
  fileInputRefNew: any;
  getPayoutsDataApiId: string = "";
  createInvoiceApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      errorMessage: null,
      comissionData: {
        leads: {
          data: [
          ]
        },
        "meta": {
          "total_pages": 0,
          "total_count": 0,
          "current_page": 0,
          "next_page": null,
          "previous_page": null
        },
        "total_conversion": null,
        "commission_earned": null,
        "value_in_pipeline": null,
        "previous_commission": 0,
        "previous_conversion": 0,
        "previous_value_in_pipeline": 0,
        "empty_state": true
      },

      isLoading: false,
      openCalendar: false,
      dateRangeValue: getStartAndEndDates('Last 90 days'),
      calendarMenu: null,
      calanderValue: 'Last 90 days',
      searchValue: '',
      currentPage: 1,
      perPage: 10,
      csvData:
      {
        "headers": [],
        "data": []
      },
      payoutsData: {
        "payout": {
          "data": [
          ]
        },
        "meta": {
          "total_pages": 0,
          "total_count": 0,
          "current_page": 0,
          "next_page": null,
          "previous_page": null
        },
        "empty_state": false
      },
      addInvoiceModal: false,
      selectedInvoiceData: {
        "attributes": {
          "commission": "0",
          "sale": "0",
          "status": null,
          "between_date": null,
          "id": 0
        }
      },
      viewInvoiceModal: false,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      selectedFile: {},
      fileError: '',
      isTableLoading: false,
      dateValue:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (getMemberErrorMessages(responseJson)) {
        removeAllToken()
        this.props.navigation.navigate('EmailAccountLoginBlock')
      } else {
        switch (apiRequestCallId) {
          case this.getComissionDataApiId:
            this.handleResponse(responseJson, false, '', 'comissionData')
            setToken('comissionData', JSON.stringify(responseJson))
            
            this.setState({
              isLoading: false,
              isTableLoading: false
            });
            this.closeCalander()
            if (responseJson.leads.data.length > 0) {
              this.downloadComissionCSV()
            }
            break;
          case this.downloadCSVComissionId:
            this.handleCSVRes(responseJson)
            break;
          case this.getPayoutsDataApiId:
            this.handleResponse(responseJson, false, '', 'payoutsData')
            break;
          case this.createInvoiceApiId:
            this.closeModal()
            this.getPayoutsData()
            break;
          default:
            // Handle the default case here
            break;
        }
        
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleTabChange(newValue: any) {
    this.setState({ tabValue: newValue })
    if (newValue === 1) {
      this.navigateToPayouts()
    } else {
      this.navigateToComission()
    }
  }
  navigateToPayouts = () => {
    this.props.navigation.navigate("Payouts")
  }
  navigateToComission = () => {
    this.props.navigation.navigate("Commissionreports2")
  }
  handleResponse(responseJson: any, navigaion: any, path: any, statedata: any) {
    if (!responseJson?.errors) {
      if (navigaion) {
        this.props.navigation.navigate(path);
      } else {
        const updatedState = { ...this.state, [statedata]: responseJson };
        this.setState(updatedState);
      }
    } else {
      // Check Error Response
      this.setState({
        errorMessage: getErrorResponse(responseJson)
      });
    }
    this.setState({isLoading:false})
  }
  getComissionData = async (type: any) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getComissionDataApiId = requestMessage.messageId;

    if (type === 'normal') {
      this.setState({ isLoading: true });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getComissionDataEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&opp_name=${this.state.searchValue}&start_date=${getYearMonthDate(this.state.dateRangeValue[0])}&end_date=${getYearMonthDate(this.state.dateRangeValue[1])}`
      );
    }
    if (type === 'filter') {
      this.setState({ isTableLoading: true });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getComissionDataEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&opp_name=${this.state.searchValue}&start_date=${getYearMonthDate(this.state.dateRangeValue[0])}&end_date=${getYearMonthDate(this.state.dateRangeValue[1])}`
      );
    }
    if (type === 'allTime') {
      this.setState({ isLoading: true });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getComissionDataEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&opp_name=${this.state.searchValue}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  closeCalander = () => {
    this.setState({
      openCalendar: false
    })
  }
  handleCalendarClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ calendarMenu: event.currentTarget, openCalendar:false });
    }
  };
  handleCalendarSelect = (text: any) => {
    this.setState({
      searchValue: ''
    })
    if (text === "Custom...") {
      if (this.state.calanderValue === 'Custom...') {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
          currentPage: 1
        })
      }else{
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
          currentPage: 1
        })
      }
    } else if (text === "All Time") {
      this.setState({ calanderValue: text, calendarMenu: null, openCalendar: false, currentPage: 1,dateValue:[] }, () => {
        this.getComissionData('allTime')
      });
    } else {
      this.setState({ calanderValue: text, calendarMenu: null, dateRangeValue: getStartAndEndDates(text), openCalendar: false, currentPage: 1, dateValue:[] }, () => {
        this.getComissionData('normal')
      });
    }
  };
  handleCalendarClose = () => {
    this.setState({ calendarMenu: null });
  }
  handleDateChange = (item: any) => {
    this.setState({ dateValue: item });
  };
  handleSearchValue(text: any) {
    this.setState({ searchValue: text, currentPage: 1 }, () => {
      this.checkAllTime()
    })
  }
  getComissionPageCount() {
    return this.state.comissionData.leads.data.length
  }
  getPayoutPageCount() {
    return this.state.payoutsData.payout.data.length
  }
  handlePageChange(value: any) {
    this.setState({ currentPage: parseInt(value) }, () => {
      this.checkAllTime()
    });
  }
  checkAllTime() {
    if (this.state.calanderValue === 'All Time' && this.state.searchValue.length === 0) {
      this.getComissionData('allTime');
    } else {
      this.getComissionData('filter');
    }
  }
  downloadComissionCSV() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadCSVComissionId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getComissionDataEndPoint}?opp_name=${this.state.searchValue}&download_csv=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  navigateToLeads(data: any) {
    if (data) {
      localStorage.setItem('company_id', data.id)
      localStorage.setItem('company_name', data.company_name)
      this.props.navigation.navigate('LeadDashboard')
    }
  }
  navigateToSingleLead(data: any) {
    if(data.type=="lead"){
      window.location.href=`/lead/${data.id}`
    }else{
      window.location.href=`/deal/${data.id}`
    }
  }
  openRaiseInvoiceModal(item: any) {
    this.setState({
      addInvoiceModal: true,
      selectedInvoiceData: item
    })
  }
  viewInvoiceModal(item: any) {
    this.setState({
      viewInvoiceModal: true,
      selectedInvoiceData: item
    })
  }
  closeModal() {
    this.setState({
      addInvoiceModal: false,
      selectedInvoiceData: {
        "attributes": {
          "commission": "0",
          "sale": "0",
          "status": null,
          "between_date": null,
          "id": 0
        }
      },
      viewInvoiceModal: false,
      isUploaded: false,
      selectedFile: {}
    })
  }
  getFormikError(error: any, erros: any) {
    return error && Boolean(erros)
  }
  getFormikHelperText(error: any, erros: any) {
    return error && erros
  }
  handleDragEnter = (e: any) => {
    e.preventDefault();
    this.setState({ isDragging: true });
  };

  handleDragLeave = (e: any) => {
    e.preventDefault();
    this.setState({ isDragging: false });
  };

  handleDrop = (e: any) => {
    e.preventDefault();
    this.setState({
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      fileError: ''
    });

    const files = e.dataTransfer.files;
    const validFiles = [];

    for (const file of files) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop(); // Get the file extension

      if (fileExtension === 'pdf') {
        // Check file size (max 5 MB)
        if (file.size <= 5 * 1024 * 1024) { // 5 MB in bytes
          validFiles.push(file);
        } else {
          this.setState({
            fileTypeError: configJSON.fileSizeError,
          });
        }
      } else {
        this.setState({
          fileTypeError: configJSON.fileTypeError,
        });
      }
    }

    if (validFiles.length > 0) {
      this.setState({
        selectedFile: validFiles[0],
        isUploaded: true
      })
    } else {
      this.setState({
        fileTypeError: configJSON.fileTypeError,
      });
    }
  };
  handleFileInputChange = (e: any) => {
    this.setState({
      fileSizeError: null,
      fileTypeError: null,
      fileError: ''
    });

    const files = e.target.files;

    for (const file of files) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop(); // Get the file extension

      if (fileExtension === 'pdf') {
        // Check file size (max 5 MB)
        if (file.size <= 5 * 1024 * 1024) { // 5 MB in bytes
          // The file is both a valid .csv or .xls file and within the size limit
          this.setState({
            selectedFile: file,
            isUploaded: true,
          })
        } else {
          // File size is too large, provide feedback to the user
          this.setState({
            fileTypeError: configJSON.fileSizeError,
          });
        }
      } else {
        // Invalid file type, you can provide feedback to the user
        this.setState({
          fileTypeError: configJSON.fileTypeError,
        });
      }
    }
  };
  getPayoutsData = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPayoutsDataApiId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPayoutsEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  createInvoce = (values: any) => {
    this.setState({ isLoading: true, addInvoiceModal: false });

    const header = {
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createInvoiceApiId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPayoutsEndPoint}/${this.state.selectedInvoiceData.attributes.id}`
    );

    const formData = new FormData()
    formData.append('invoice_file', this.state.selectedFile);
    formData.append('additional_deatils', values.additional_details);
    formData.append('raised_amount', values.invoice_amount);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  openReInvoiceModal = () => {
    this.setState({
      addInvoiceModal: true
    })
  }

  handleCSVRes=(responseJson:any)=>{
    if(!responseJson?.error)
      this.handleResponse(responseJson, false, '', 'csvData');
  }
  // Customizable Area End
}
